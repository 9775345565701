import React from "react";
import JsFileDownloader from "js-file-downloader";
import styles from "./Carrousel.module.sass";
import { toPng } from "html-to-image";
import cn from "classnames";
import JSZip from "jszip";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Textfit } from "@ayushmw/react-textfit";
import { useDispatch, useSelector } from "react-redux";
import { parseSearchCardTags, preloadImage } from "../../../../utils";
import { fetchCards } from "../../../../app/cards/cards.slice";
import DropdownSearch from "../../../../components/DropdownSearch";
import { useEffect } from "react";
import Dropdown from "../../../../components/Dropdown";
import { fetchDecks } from "../../../../app/decks/decks.slice";
import Switch from "../../../../components/Switch";
import { BiDownload, BiImage, BiPalette, BiVideo, BiX } from "react-icons/bi";
import Checkbox from "../../../../components/Checkbox";
import domtoimage from "dom-to-image-more";

const LANGUAGES = [
  { label: "English", value: "en" },
  { label: "Español", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "French", value: "fr" },
  { label: "Russian", value: "ru" },
];

const Carrousel = ({ selectedCardProp, selectedDeckProp, setSelectedCardProp, setSelectedDeckProp }) => {
  const dispatch = useDispatch();
  const { decks, cards } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const [decksOptions, setDecksOptions] = useState(decks);

  const [selectedCard, setSelectedCard] = useState("");
  const [selectedDeck, setSelectedDeck] = useState("");
  const [languageSelected, setLanguageSelected] = useState(["en"]);

  const [isBackground, setIsBackground] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isPlusBadge, setIsPlusBadge] = useState(false);
  const [isLanguageFlags, setIsLanguageFlags] = useState(true);

  const [colorSelected, setColorSelected] = useState("#F4F4F4");
  const [imageSelected, setImageSelected] = useState("");

  const ref_1 = useRef(null);
  const ref_1_DeckImg = useRef(null);
  const ref_2 = useRef(null);
  const ref_3 = useRef(null);
  const ref_4 = useRef(null);
  const ref_5 = useRef(null);

  useEffect(() => {
    if (selectedCardProp && selectedDeckProp) {
      setSelectedCard(selectedCardProp);
      setSelectedDeck(selectedDeckProp);

      if (ref_1_DeckImg.current) {
        preloadImage(
          selectedDeckProp?.deck?.avatar?.miniUrl ||
            selectedDeckProp?.deck?.avatar?.mediumUrl ||
            "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
        ).then((img) => {
          ref_1_DeckImg.current.src = img.src;
        });
      }
    }
  }, [selectedDeckProp, selectedCardProp]);

  const handleSearchCard = (term) => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
        // deck: selectedDeck?.deck?._id,
        search: term,
      })
    );
  };

  const handleSearchDeck = (term) => {
    dispatch(
      fetchDecks({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  const handleDownloadVideos = () => {
    console.log("descargando videos...");

    new JsFileDownloader({
      url: selectedCard?.card?.meaning?.videoUrl,
      filename: `video_meaning.mp4`,
    });

    selectedCard?.card?.examples?.forEach(async (e, i) => {
      new JsFileDownloader({
        url: e?.videoUrl,
        filename: `video_example_${i + 1}.mp4`,
      });
    });
  };

  const handleAddImage = (e) => {
    console.log("e", e.target.files);
    const [file] = e.target.files;
    if (file) {
      let url = URL.createObjectURL(file);
      setImageSelected(url);
    }
  };

  const handleDownloadPNG = useCallback(
    async (type) => {
      setIsLoading(true);
      if (ref_1.current === null) {
        return;
      }

      let dataUrl1, dataUrl2, dataUrl3, dataUrl4, dataUrl5;

      if (type === 1 || type === "all") {
        dataUrl1 = await domtoimage.toPng(ref_1.current, { scale: 4 });
      }

      if (type === 2 || type === "all") {
        dataUrl2 = await domtoimage.toPng(ref_2.current, { scale: 4 });
      }

      if (type === 3 || type === "all") {
        dataUrl3 = await domtoimage.toPng(ref_3.current, { scale: 4 });
      }

      if (type === 4 || type === "all") {
        dataUrl4 = await domtoimage.toPng(ref_4.current, { scale: 4 });
      }

      if (type === 5 || type === "all") {
        dataUrl5 = await domtoimage.toPng(ref_5.current, { scale: 4 });
      }

      const dataUrls = [dataUrl1, dataUrl2, dataUrl3, dataUrl4, dataUrl5];

      var w = window.open("", "_blank");

      dataUrls.forEach((dUrl) => {
        var img = new Image();
        img.src = dUrl;
        w.document.write(img.outerHTML);
      });

      setIsLoading(false);
    },
    [ref_1, ref_2, ref_3, ref_4, ref_5]
  );

  useEffect(() => {
    console.log("decks", decks);
    console.log("selectedCard", selectedCard);
  }, [selectedCard, decks]);

  useEffect(() => {
    dispatch(fetchDecks());
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      setColorSelected("#424557");
    } else {
      setColorSelected("#F4F4F4");
    }
  }, [isDarkMode]);

  const getSize = (count) => {
    if (count < 4) {
      return "14px";
    } else if (count >= 4 && count < 7) {
      return "12px";
    } else if (count >= 7 && count < 9) {
      return "11px";
    } else if (count >= 9 && count < 12) {
      return "10px";
    } else if (count >= 12 && count < 15) {
      return "9px";
    } else if (count >= 15 && count < 20) {
      return "8px";
    } else {
      return "7px";
    }
  };

  const countWords = (frase) => {
    // Verifica si la frase está vacía o compuesta solo por espacios en blanco
    if (frase.trim() === "") {
      return 0;
    }

    // Separa la frase en palabras usando espacio como separador y cuenta las palabras
    const palabras = frase.trim().split(/\s+/);
    return palabras.length;
  };

  console.log("selectedDeck --> ", selectedDeck);

  return (
    <div className={styles.containerFlex}>
      <div
        className={styles["col-span-2"]}
        style={{ background: "white", padding: 20, justifyContent: "center", display: "flex" }}
      >
        <div style={{ width: "700px" }}>
          {!selectedCard || !selectedDeck ? (
            <div>
              <h2 style={{ color: "gray" }}>Please select a Card & Deck to proceed...</h2>
            </div>
          ) : (
            <div className={styles.grid2}>
              <div
                ref={ref_1}
                style={{
                  // 1 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    left: 0,
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isDarkMode ? (
                    <img src="/images/logo-complete-light.svg" style={{ width: "40%" }} />
                  ) : (
                    <img src="/images/logo-complete.svg" style={{ width: "40%" }} />
                  )}
                </div>
                <div
                  style={{
                    width: imageSelected ? "200px" : "250px",
                    height: imageSelected ? "200px" : "250px",
                    marginTop: 20,
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: imageSelected ? "200px" : "250px",
                      height: imageSelected ? "150px" : "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "80%", textAlign: "center", fontWeight: "400", position: "relative" }}>
                      {imageSelected ? (
                        <span style={{ fontSize: "3vw" }}>{selectedCard?.character}</span>
                      ) : (
                        <Textfit mode="single">{selectedCard?.character}</Textfit>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      bottom: 15,
                      left: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={
                        selectedDeck?.deck?.avatar?.miniUrl ||
                        selectedDeck?.deck?.avatar?.mediumUrl ||
                        "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
                      }
                      style={{ width: "50px" }}
                      // onError={(err) => {
                      //   const { currentTarget } = err;
                      //   console.log("ERRO --> ", err);
                      //   currentTarget.onerror = null; // prevents looping
                      //   currentTarget.src = "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                      // }}
                    />
                    <div style={{ marginLeft: 15 }}>
                      <div style={{ color: "lightgray", fontWeight: "200" }}>
                        {selectedDeck?.deck?.subCategory?.category?.title}
                      </div>
                      <div>
                        {selectedDeck?.deck?.translations?.filter((c) => c?.lang === "en")?.[0]?.trans ||
                          selectedDeck?.deck?.key}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref_2}
                style={{
                  // 2 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    left: 0,
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isDarkMode ? (
                    <img src="/images/logo-complete-light.svg" style={{ width: "40%" }} />
                  ) : (
                    <img src="/images/logo-complete.svg" style={{ width: "40%" }} />
                  )}
                </div>
                <div
                  style={{
                    width: "250px",
                    height: "250px",
                    marginTop: 20,
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div style={{ width: "100%", textAlign: "center", fontWeight: "400", position: "relative" }}>
                    <div style={{ fontSize: "50px" }}>{selectedCard?.character}</div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                      <span style={{ color: "#D5D5DC", fontSize: "12px" }}>PY</span>
                      <span style={{ color: isDarkMode ? "#767EF0" : "#2934D0", fontSize: "16px", marginLeft: 5 }}>
                        {selectedCard?.card?.meaning?.pinyin}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      borderTop: isDarkMode ? "1px solid #565698" : "1px solid #F1F1F5",
                      width: "100%",
                      marginTop: 10,
                    }}
                  ></div>

                  <div style={{ marginTop: 10 }}>
                    {selectedCard?.card?.meaning?.translations?.map((c) => {
                      if (c?.active && languageSelected?.includes(c?.lang)) {
                        return (
                          <div
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}
                          >
                            {isLanguageFlags && <img style={{ width: 20 }} src={`/images/flags/${c?.lang}.svg`} />}
                            <span style={{ marginLeft: 5 }}>{c?.trans?.split(",")?.[0]?.split(";")?.[0]}</span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div
                ref={ref_3}
                style={{
                  // 3 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "300px",
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                    <div style={{ width: "90%", textAlign: "center", fontWeight: "400", position: "relative" }}>
                      <div style={{ fontSize: "14px", fontWeight: "600" }}>
                        {selectedCard?.card?.examples?.[0]?.character}
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span style={{ color: isDarkMode ? "#767EF0" : "#2934D0", fontSize: "12px", marginLeft: 5 }}>
                          {selectedCard?.card?.examples?.[0]?.pinyin}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderTop: isDarkMode ? "1px dashed #565698" : "1px dashed #F1F1F5",
                      width: "100%",
                      marginTop: 10,
                    }}
                  ></div>

                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                    <div style={{ marginTop: 10, fontSize: "12px", width: "90%" }}>
                      {selectedCard?.card?.examples?.[0]?.translations?.map((c) => {
                        if (c?.active && languageSelected?.includes(c?.lang)) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginTop: 5,
                              }}
                            >
                              {isLanguageFlags && <img style={{ width: 20 }} src={`/images/flags/${c?.lang}.svg`} />}
                              <span
                                style={{ marginLeft: 5, fontWeight: "300", fontSize: getSize(countWords(c?.trans)) }}
                              >
                                {c?.trans}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref_4}
                style={{
                  // 4 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "250px",
                    height: "300px",
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                    <div style={{ width: "90%", textAlign: "center", fontWeight: "400", position: "relative" }}>
                      <div style={{ fontSize: "14px", fontWeight: "600" }}>
                        {selectedCard?.card?.examples?.[1]?.character}
                      </div>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span style={{ color: isDarkMode ? "#767EF0" : "#2934D0", fontSize: "12px", marginLeft: 5 }}>
                          {selectedCard?.card?.examples?.[1]?.pinyin}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderTop: isDarkMode ? "1px dashed #565698" : "1px dashed #F1F1F5",
                      width: "100%",
                      marginTop: 10,
                    }}
                  ></div>

                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
                    <div style={{ marginTop: 10, fontSize: "12px", width: "90%" }}>
                      {selectedCard?.card?.examples?.[1]?.translations?.map((c) => {
                        if (c?.active && languageSelected?.includes(c?.lang)) {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginTop: 5,
                              }}
                            >
                              {isLanguageFlags && <img style={{ width: 20 }} src={`/images/flags/${c?.lang}.svg`} />}
                              <span
                                style={{ marginLeft: 5, fontWeight: "300", fontSize: getSize(countWords(c?.trans)) }}
                              >
                                {c?.trans}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref_5}
                style={{
                  // 5 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "250px",
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    top: 24,
                    paddingInline: "14px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      marginTop: 10,
                    }}
                  >
                    <img
                      src={
                        selectedDeck?.deck?.avatar?.miniUrl ||
                        selectedDeck?.deck?.avatar?.mediumUrl ||
                        "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
                      }
                      style={{ width: "100px" }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                      }}
                    />
                    <div style={{ display: "block" }}>
                      {selectedDeck?.deck?.new && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <img src="/images/Logonew.svg" style={{ width: "50px" }} />
                        </div>
                      )}
                      {isPlusBadge && (
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 4 }}>
                          <img src="/images/Logoplus.svg" />
                        </div>
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: 10 }}>
                    <div style={{ textTransform: "uppercase", color: "#B5B5BE", fontSize: "12px" }}>
                      {selectedDeck?.deck?.level}
                    </div>
                    <div style={{}}>{selectedDeck?.deck?.title}</div>
                  </div>

                  <div style={{ position: "absolute", bottom: 15, left: 0, width: "100%", paddingInline: "14px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ display: "flex", alignItems: "center", color: "#2934D0" }}>
                        <span style={{ fontSize: "24px" }}>{selectedDeck?.deck?.size}</span>
                        <span style={{ marginLeft: 2 }}>words</span>
                      </div>
                      <div style={{ color: "#2ED0A0" }}>Start now!</div>
                    </div>
                    <div style={{ width: "100%", borderRadius: "999px", background: "#EEEEEF", height: "12px" }}></div>
                  </div>

                  {isLanguageFlags && (
                    <div style={{ position: "absolute", bottom: -15, left: 0, width: "100%", paddingInline: "14px" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {languageSelected?.map((c, i) => (
                          <img src={`/images/flags/${c}.svg`} style={{ width: 23, marginLeft: i > 0 ? 10 : 0 }} />
                        ))}
                      </div>
                    </div>
                  )}

                  <div style={{ position: "absolute", bottom: -110, left: 0, width: "100%", paddingInline: "14px" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img style={{ width: 130 }} src={`/images/googleplaycolor.svg`} />
                      <img src={`/images/appstore.svg`} style={{ marginLeft: 10 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.separator}>
        <DropdownSearch
          className={styles.field}
          isBackgroundWhite
          label="Deck"
          value={selectedDeck}
          placeholder="Search Decks"
          setValue={(e) => {
            setSelectedDeck(e);
            setSelectedDeckProp(e);
          }}
          options={decks.value.map((deck) => {
            return { label: deck?.key, value: deck?.key, deck: deck };
          })}
          isLoading={decks.isLoading}
          timeoutSearch={500}
          onSearch={handleSearchDeck}
        />
        <DropdownSearch
          className={styles.field}
          isBackgroundWhite
          label="Card"
          isDelete
          value={selectedCard}
          placeholder="Search Cards"
          setValue={(e) => {
            setSelectedCard(e);
            setSelectedCardProp(e);
          }}
          options={cards.value.map((card) => parseSearchCardTags(card))}
          isLoading={cards.isLoading}
          timeoutSearch={500}
          onSearch={handleSearchCard}
        />
        <div>
          <div style={{ marginBottom: 10 }}>
            <label style={{ fontSize: 14 }}>Language</label>
          </div>
          <div className={styles?.grid2}>
            {LANGUAGES?.map((c) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  className={styles.checkbox}
                  classCheckboxTick={styles.checkboxTick}
                  value={languageSelected?.includes(c?.value)}
                  onChange={() =>
                    languageSelected?.includes(c?.value)
                      ? setLanguageSelected((languageSelected) => [...languageSelected?.filter((f) => f !== c?.value)])
                      : setLanguageSelected((languageSelected) => [...languageSelected, c?.value])
                  }
                />
                <div style={{ marginLeft: 10 }}>{c?.label}</div>
              </div>
            ))}
          </div>
        </div>
        {/* <Dropdown
                    className={styles.field}
                    classDropdownLabel={styles.label}
                    setValue={setLanguageSelected}
                    value={languageSelected}
                    label="Language"
                    isWhite
                    options={[
                        {label: 'English', value: 'en'},
                        {label: 'Español', value: 'es'},
                        {label: 'Portuguese', value: 'pt'},
                        {label: 'French', value: 'fr'},
                        {label: 'Russian', value: 'ru'},
                    ]}
                /> */}
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Background</label>
          <Switch value={isBackground} onChange={() => setIsBackground(!isBackground)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Dark mode</label>
          <Switch value={isDarkMode} onChange={() => setIsDarkMode(!isDarkMode)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Plus badge</label>
          <Switch value={isPlusBadge} onChange={() => setIsPlusBadge(!isPlusBadge)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Languages flags</label>
          <Switch value={isLanguageFlags} onChange={() => setIsLanguageFlags(!isLanguageFlags)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>
            <BiPalette />
            <span style={{ marginLeft: 5 }}>Change bg color</span>
          </label>
          <input
            type="color"
            value={colorSelected}
            onChange={(e) => setColorSelected(e.target.value)}
            style={{ marginLeft: 10 }}
          />
        </div>
        {imageSelected ? (
          <>
            <button
              onClick={() => setImageSelected("")}
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Remove bg image</span>
            </button>
          </>
        ) : (
          <>
            <input
              onChange={handleAddImage}
              style={{ display: "none" }}
              type="file"
              id="file"
              name="file"
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
            <label
              for="file"
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Add bg image</span>
            </label>
          </>
        )}
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div className={styles.onMobile}>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(1)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 1</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(2)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 2</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(3)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 3</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(4)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 4</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(5)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 5</span>
          </button>
        </div>
        <button
          disabled={!selectedCard || !selectedDeck}
          onClick={() => handleDownloadPNG("all")}
          style={{ width: "100%" }}
          className={cn("button-normal", styles.button)}
        >
          <BiDownload />
          <span>Download PNGs</span>
        </button>
        <button
          disabled={!selectedCard || !selectedDeck}
          onClick={handleDownloadVideos}
          style={{ width: "100%" }}
          className={cn("button-normal", styles.button)}
        >
          <BiVideo />
          <span>Download videos</span>
        </button>
      </div>
    </div>
  );
};

export default Carrousel;
