import React from "react";
import styles from "./NewDeck.module.sass";
import { toPng } from "html-to-image";
import cn from "classnames";
import JSZip from "jszip";
import { useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { Textfit } from "@ayushmw/react-textfit";
import { useDispatch, useSelector } from "react-redux";
import { parseSearchCardTags } from "../../../../utils";
import { fetchCards } from "../../../../app/cards/cards.slice";
import DropdownSearch from "../../../../components/DropdownSearch";
import { useEffect } from "react";
import Dropdown from "../../../../components/Dropdown";
import { fetchDecks } from "../../../../app/decks/decks.slice";
import Switch from "../../../../components/Switch";
import { BiDownload, BiImage, BiPalette, BiVideo, BiX } from "react-icons/bi";
import { AiFillSound } from "react-icons/ai";
import Checkbox from "../../../../components/Checkbox";
import domtoimage from "dom-to-image-more";
import { getTexts } from "../../../../api/texts.service";

const LANGUAGES = [
  { label: "English", value: "en" },
  { label: "Español", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "French", value: "fr" },
  { label: "Russian", value: "ru" },
];

const NewText = ({ selectedCardProp, selectedDeckProp, setSelectedCardProp, setSelectedDeckProp }) => {
  const dispatch = useDispatch();
  const { decks, cards } = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const [decksOptions, setDecksOptions] = useState(decks);

  const [selectedCard, setSelectedCard] = useState("");
  const [selectedDeck, setSelectedDeck] = useState("");
  const [languageSelected, setLanguageSelected] = useState(["en"]);

  const [isBackground, setIsBackground] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isPlusBadge, setIsPlusBadge] = useState(false);
  const [isLanguageFlags, setIsLanguageFlags] = useState(true);

  const [colorSelected, setColorSelected] = useState("#2ED0A0");
  const [imageSelected, setImageSelected] = useState("");

  const [dataText, setDataText] = useState([])

  const [selectedText, setSelectedText] = useState('')

  const ref_1 = useRef(null);
  const ref_2 = useRef(null);

  const handleSearchCard = (term) => {
    dispatch(
      fetchCards({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  useEffect(() => {
    if (selectedCardProp && selectedDeckProp) {
      setSelectedCard(selectedCardProp);
      setSelectedDeck(selectedDeckProp);
    }
  }, [selectedDeckProp, selectedCardProp]);

  const handleSearchDeck = (term) => {
    dispatch(
      fetchDecks({
        take: 100,
        skip: 0,
        search: term,
      })
    );
  };

  const handleDownloadVideos = () => {
    console.log("descargando videos...");
  };

  const handleAddImage = (e) => {
    console.log("e", e.target.files);
    const [file] = e.target.files;
    if (file) {
      let url = URL.createObjectURL(file);
      setImageSelected(url);
    }
  };

  const handleDownloadPNG = useCallback(
    async (type) => {
      setIsLoading(true);
      if (ref_1.current === null) {
        return;
      }

      let dataUrl1, dataUrl2;

      if (type === 1 || type === "all") {
        dataUrl1 = await domtoimage.toPng(ref_1.current, { scale: 4 });
      }

      if (type === 2 || type === "all") {
        dataUrl2 = await domtoimage.toPng(ref_2.current, { scale: 4 });
      }

      const dataUrls = [dataUrl1, dataUrl2];

      var w = window.open("", "_blank");

      dataUrls.forEach((dUrl) => {
        var img = new Image();
        img.src = dUrl;
        w.document.write(img.outerHTML);
      });

      setIsLoading(false);
    },
    [ref_1, ref_2]
  );
  useEffect(() => {
    console.log("decks", decks);
    console.log("selectedCard", selectedCard);
  }, [selectedCard, decks]);

  useEffect(() => {
    dispatch(fetchDecks());
    handleGetNews()
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      setColorSelected("#424557");
    } else {
      setColorSelected("#2ED0A0");
    }
  }, [isDarkMode]);

  const handleGetNews = async () =>{
    let data = await getTexts()
    console.log("data texts", data)

    if(data?.data?.results){
      setDataText(data?.data?.results)
    }
  }

  function extraerNumero(str) {
    const resultado = str?.match(/\d+/); // Esta expresión regular busca uno o más dígitos
    return resultado ? parseInt(resultado[0]) : null;
  }

  function hexToRgba(hex, opacity = 0.2) {
    // Remove the '#' if it exists
    hex = hex.replace('#', '');
  
    // Parse the HEX color into RGB components
    let r, g, b;
    if (hex.length === 3) {
      // Handle shorthand HEX (e.g., #abc)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
      // Handle full HEX (e.g., #aabbcc)
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    } else {
      throw new Error('Invalid HEX color format');
    }
  
    // Return the RGBA string with the specified opacity
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  
  // Example usage:
  console.log(hexToRgba('#1a73e8')); // rgba(26, 115, 232, 0.5)
  console.log(hexToRgba('#abc', 0.7)); // rgba(170, 187, 204, 0.7)

  const handleGetColorLevel = (lvl) => {
    const COLORS = {
      1: "#83BF6E",
      2: "#78CEFF",
      3: "#5E5BF6",
      4: "#EDE63E",
      5: "#F0B43F",
      6: "#F05D5D",
      7: "#C055E5",
    };

    if (lvl?.title) {
      return COLORS?.[extraerNumero(lvl?.title)] || "#d3d3d3";
    } else {
      return "#d3d3d3";
    }
  };

  const levelColors = {
    Newbie: "#4690FF",
    Begginer: "#83BF6E",
    Intermediate: "#FF4646",
    Advanced: "#FF4646",
    Master: "#FF4646",
  };

  return (
    <div className={styles.containerFlex}>
      <div
        className={styles["col-span-2"]}
        style={{ background: "white", padding: 20, justifyContent: "center", display: "flex" }}
      >
        <div style={{ width: "700px" }}>
          {!selectedText ? (
            <div>
              <h2 style={{ color: "gray" }}>Please select a Text to proceed...</h2>
            </div>
          ) : (
            <div className={styles.grid2}>
              <div
                ref={ref_1}
                style={{
                  // 1 Panel
                  width: "350px",
                  height: "622px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  borderRadius: "6px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 0,
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "999px",
                      paddingInline: "20px",
                      width: "40%",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <img src="/images/logo-complete.svg" style={{ width: "100%" }} />
                  </div>
                </div>

                <div style={{ marginTop: 60 }}>
                  <div
                    style={{
                      width: "250px",
                      minHeight: "280px",
                      background: isDarkMode ? "#2A2C38" : "white",
                      borderRadius: "12px",
                      paddingInline: "14px",
                      color: isDarkMode ? "white" : "black",
                      border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                      boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                      position: "relative",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginTop: 20,
                          width: "100%",
                        }}
                      >
                        <div style={{width: '100%', height: 120, position: 'relative', borderRadius: "12px"}}>
                          <img
                            src={
                              selectedText?.text?.imageUrl ||
                              "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
                            }
                            style={{ width: "100%", borderRadius: "12px", height: '100%', objectFit: 'cover' }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                            }}
                          />
                        </div>
                        <div style={{ display: "block", position: 'absolute', top: 22, left: 20 }}>
                          {isPlusBadge && (
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 4 }}>
                              <img src="/images/Logoplus.svg" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{ fontSize: "14px", display: 'flex', alignItems: 'center' }}>
                          <div style={{backgroundColor: levelColors?.[selectedText?.text?.level], borderRadius: 20, width: 4, height: 15, marginRight: 4}} />
                          <span style={{color: 'gray',opacity: 0.8, fontWeight: 'normal'}}>{selectedText?.text?.level}</span>
                        </div>
                        <div style={{fontSize: "14px", marginLeft: 15}}>
                          <svg style={{fill: 'gray'}} width="16" height="16" viewBox="0 0 24 24"><path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"></path></svg>
                          <span style={{color: 'gray',opacity: 0.8, fontWeight: 'normal', marginLeft: 4}}>
                            {selectedText?.text?.analysis?.words}
                          </span>
                        </div>
                      </div>
                      <div style={{ fontSize: "16px", fontWeight: 600, lineHeight: 1, marginTop: 10 }}>{selectedText?.text?.title?.find(c => c?.lang === 'en')?.trans}</div>
                      <div style={{ fontSize: "14px", marginTop: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: 'nowrap', fontWeight: 'normal', opacity: 0.6 }}>{selectedText?.text?.description?.find(c => c?.lang === 'en')?.trans}</div>
                      {extraerNumero(selectedText?.text?.suitableForHsk?.title || '') > 0 &&
                        <div style={{marginTop: 10, display: 'flex'}}>
                          <div style={{ color: handleGetColorLevel(selectedText?.text?.suitableForHsk), backgroundColor: hexToRgba(handleGetColorLevel(selectedText?.text?.suitableForHsk)), fontSize: 14, paddingLeft: 10, paddingRight: 10, borderRadius: 999 }}>
                            {selectedText?.text?.suitableForHsk?.title}
                          </div>
                        </div>
                      }
                    </div>

                    {isLanguageFlags && (
                      <div style={{ position: "absolute", bottom: -15, left: 0, width: "100%", paddingInline: "14px" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {languageSelected?.map((c, i) => (
                            <img src={`/images/flags/${c}.svg`} style={{ width: 23, marginLeft: i > 0 ? 5 : 0 }} />
                          ))}
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        position: "absolute",
                        top: -100,
                        left: -3,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingInline: "14px",
                      }}
                    >
                      <div style={{ fontSize: 30, color: "white", fontWeight: 300 }}>New text!</div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref_2}
                style={{
                  // 2 Panel
                  width: "350px",
                  height: "350px",
                  backgroundColor: colorSelected,
                  backgroundImage: `url('${imageSelected}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  justifyContent: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    width: "200px",
                    height: "250px",
                    background: isDarkMode ? "#2A2C38" : "white",
                    borderRadius: "12px",
                    top: 24,
                    right: 24,
                    paddingInline: "14px",
                    color: isDarkMode ? "white" : "black",
                    border: isDarkMode ? "0.65px solid #252839" : "0.65px solid #FAFAFB",
                    boxShadow: isDarkMode ? "0px 4px 0px 0px #1D1F2C" : "0px 4px 0px 0px #F3F3F3",
                    position: "relative",
                  }}
                >
                  <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginTop: 20,
                          width: "100%",
                        }}
                      >
                        <div style={{width: '100%', height: 80, position: 'relative', borderRadius: "12px"}}>
                          <img
                            src={
                              selectedText?.text?.imageUrl ||
                              "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png"
                            }
                            style={{ width: "100%", borderRadius: "12px", height: '100%', objectFit: 'cover' }}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "https://decksboostchinese.s3.eu-west-3.amazonaws.com/-1.png";
                            }}
                          />
                        </div>
                        <div style={{ display: "block", position: 'absolute', top: 22, left: 20 }}>
                          {isPlusBadge && (
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 4 }}>
                              <img src="/images/Logoplus.svg" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                  <div style={{ marginTop: 10 }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div style={{ fontSize: "14px", display: 'flex', alignItems: 'center' }}>
                        <div style={{backgroundColor: levelColors?.[selectedText?.text?.level], borderRadius: 20, width: 4, height: 15, marginRight: 4}} />
                        <span style={{color: 'gray',opacity: 0.8, fontWeight: 'normal'}}>{selectedText?.text?.level}</span>
                      </div>
                      <div style={{fontSize: "14px", marginLeft: 15}}>
                        <svg style={{fill: 'gray'}} width="16" height="16" viewBox="0 0 24 24"><path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"></path></svg>
                        <span style={{color: 'gray',opacity: 0.8, fontWeight: 'normal', marginLeft: 4}}>
                          {selectedText?.text?.analysis?.words}
                        </span>
                      </div>
                    </div>
                    <div style={{ fontSize: "16px", fontWeight: 600, lineHeight: 1, marginTop: 10 }}>{selectedText?.text?.title?.find(c => c?.lang === 'en')?.trans}</div>
                    <div style={{ fontSize: "14px", marginTop: 5, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: 'nowrap', fontWeight: 'normal', opacity: 0.6 }}>{selectedText?.text?.description?.find(c => c?.lang === 'en')?.trans}</div>
                    {extraerNumero(selectedText?.text?.suitableForHsk?.title) > 0 &&
                      <div style={{marginTop: 10, display: 'flex'}}>
                        <div style={{ color: handleGetColorLevel(selectedText?.text?.suitableForHsk), backgroundColor: hexToRgba(handleGetColorLevel(selectedText?.text?.suitableForHsk)), fontSize: 14, paddingLeft: 10, paddingRight: 10, borderRadius: 999 }}>
                            {selectedText?.text?.suitableForHsk?.title}
                        </div>
                      </div>
                    }
                  </div>

                  {isLanguageFlags && (
                    <div style={{ position: "absolute", bottom: -15, left: 0, width: "100%", paddingInline: "14px" }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {languageSelected?.map((c, i) => (
                          <img src={`/images/flags/${c}.svg`} style={{ width: 23, marginLeft: i > 0 ? 5 : 0 }} />
                        ))}
                      </div>
                    </div>
                  )}

                  <div style={{ position: "absolute", left: -190, paddingInline: "14px", bottom: 20 }}>
                    <div style={{ transform: "rotate(-90deg)", fontSize: 40, color: "white" }}>New text!</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.separator}>
        <DropdownSearch
          className={styles.field}
          isBackgroundWhite
          label="Text"
          value={selectedText}
          placeholder="Search Texts"
          setValue={(e) => {
            setSelectedText(e);
          }}
          options={dataText.map((text) => {
            return { label: text?.title?.find(c => c?.lang === 'en')?.trans || text?.titleTrans, value: text?._id, text: text };
          })}
        />
        <div>
          <div style={{ marginBottom: 10 }}>
            <label style={{ fontSize: 14 }}>Language</label>
          </div>
          <div className={styles?.grid2}>
            {LANGUAGES?.map((c) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  className={styles.checkbox}
                  classCheckboxTick={styles.checkboxTick}
                  value={languageSelected?.includes(c?.value)}
                  onChange={() =>
                    languageSelected?.includes(c?.value)
                      ? setLanguageSelected((languageSelected) => [...languageSelected?.filter((f) => f !== c?.value)])
                      : setLanguageSelected((languageSelected) => [...languageSelected, c?.value])
                  }
                />
                <div style={{ marginLeft: 10 }}>{c?.label}</div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Background</label>
          <Switch value={isBackground} onChange={() => setIsBackground(!isBackground)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Dark mode</label>
          <Switch value={isDarkMode} onChange={() => setIsDarkMode(!isDarkMode)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Plus badge</label>
          <Switch value={isPlusBadge} onChange={() => setIsPlusBadge(!isPlusBadge)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>Languages flags</label>
          <Switch value={isLanguageFlags} onChange={() => setIsLanguageFlags(!isLanguageFlags)} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <label>
            <BiPalette />
            <span style={{ marginLeft: 5 }}>Change bg color</span>
          </label>
          <input
            type="color"
            value={colorSelected}
            onChange={(e) => setColorSelected(e.target.value)}
            style={{ marginLeft: 10 }}
          />
        </div>
        {imageSelected ? (
          <>
            <button
              onClick={() => setImageSelected("")}
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Remove bg image</span>
            </button>
          </>
        ) : (
          <>
            <input
              onChange={handleAddImage}
              style={{ display: "none" }}
              type="file"
              id="file"
              name="file"
              accept="image/png, image/jpeg, image/jpg, image/webp"
            />
            <label
              for="file"
              style={{ background: "white", width: "100%" }}
              className={cn("button-small button-stroke", styles.button)}
            >
              <BiImage />
              <span>Add bg image</span>
            </label>
          </>
        )}
        <div style={{ borderTop: "1px solid lightgray", width: "100%" }}> </div>
        <div className={styles.onMobile}>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(1)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 1</span>
          </button>
          <button
            disabled={!selectedCard || !selectedDeck}
            onClick={() => handleDownloadPNG(2)}
            style={{ width: "100%" }}
            className={cn("button-normal", styles.button)}
          >
            <BiDownload />
            <span>Download 2</span>
          </button>
        </div>
        <button
          disabled={!selectedText}
          onClick={() => handleDownloadPNG("all")}
          style={{ width: "100%" }}
          className={cn("button-normal", styles.button)}
        >
          <BiDownload />
          <span>Download PNGs</span>
        </button>
      </div>
    </div>
  );
};

export default NewText;
